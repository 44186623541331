import anime from 'animejs/lib/anime.es.js';

export function introductionFunc(){
	let introduction = $("#introductionTop").length;

	if(introduction){

		let resizecheck = $('#responsibleCheck').width();

		$(window).on('resize', function () {
		  resizecheck = $('#responsibleCheck').width();
		});

		function playVideos(videoWrappers) {
			const startPosition = window.pageYOffset + window.innerHeight;
			for(let i = 0; i < videoWrappers.length; i++) {
				const videoPosition = videoWrappers[i].getBoundingClientRect().top + window.pageYOffset;
				if(startPosition > videoPosition) {
					const video = videoWrappers[i].getElementsByTagName('video');
					video[0].play();
				}
			}
		  }
		  const videoWrappers = document.getElementsByClassName('videoControl');
		  if(videoWrappers.length) {
			  playVideos(videoWrappers);
			  window.addEventListener('scroll', function() {
				  playVideos(videoWrappers);
			  }, false);
		}

		let graphAnimationFlag01 = false;
		let graphAnimationFlag02 = false;
		let graphAnimationFlag03 = false;
		let graphAnimationFlag04 = false;
		let graphAnimationFlag05 = false;
		let graphAnimationFlag06 = false;

		function numbersAreaFunc(){
			if (resizecheck == '2') {
				scrHightNum = 0;
			} else if (resizecheck == '1') {
				scrHightNum = 200;
			}

			const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms));
		
			let scrHightNum;
		
			$('.numAnime1').each(function () {
				var position = $(this).offset().top;
				var scroll = $(window).scrollTop();
				var windowHeight = $(window).height();
		
				let roundDateLogEl01 = document.querySelector('.numAnime1');
		
				if (scroll > position - windowHeight + scrHightNum) {
				if (!graphAnimationFlag01) {
					graphAnimationFlag01 = true;
					wait(500)
					.then(() => {
						anime({
							targets: roundDateLogEl01,
							innerHTML: [0, 1 , 2 , 3 , 4 , 5, 6, 7 , 8, 9 , 0, 1],
							easing: 'easeInOutExpo',
							duration: 250,
							round: 1,
						});
					})
				}
				
				}
			});

			$('.numAnime3').each(function () {
				var position = $(this).offset().top;
				var scroll = $(window).scrollTop();
				var windowHeight = $(window).height();
		
				let roundDateLogEl02 = document.querySelector('.numAnime3');
		
				if (scroll > position - windowHeight + scrHightNum) {
				if (!graphAnimationFlag02) {
					graphAnimationFlag02 = true;
					wait(500)
					.then(() => {
						anime({
							targets: roundDateLogEl02,
							innerHTML: [0, 90],
							easing: 'easeInOutExpo',
							duration: 1000,
							round: 1,
						});
					})
				}
				
				}
			});

			$('.num01').each(function () {
				var position = $(this).offset().top;
				var scroll = $(window).scrollTop();
				var windowHeight = $(window).height();
		
				let roundDateLogEl03 = document.querySelector('.num01');
		
				if (scroll > position - windowHeight + scrHightNum) {
				if (!graphAnimationFlag03) {
					graphAnimationFlag03 = true;
					wait(500)
					.then(() => {
						anime({
							targets: roundDateLogEl03,
							innerHTML: [0, 1 , 2 , 3 , 4 , 5, 6, 7 , 8, 9 , 0],
							easing: 'easeInOutExpo',
							duration: 600,
							round: 1,
						});
					})
				}
				
				}
			});
			$('.num02').each(function () {
				var position = $(this).offset().top;
				var scroll = $(window).scrollTop();
				var windowHeight = $(window).height();
		
				let roundDateLogEl04 = document.querySelector('.num02');
		
				if (scroll > position - windowHeight + scrHightNum) {
				if (!graphAnimationFlag04) {
					graphAnimationFlag04 = true;
					wait(500)
					.then(() => {
						anime({
							targets: roundDateLogEl04,
							innerHTML: [0, 1 , 2 , 3 , 4 , 5, 6, 7 , 8, 9 , 0, 1],
							easing: 'easeInOutExpo',
							duration: 600,
							round: 1,
						});
					})
				}
				
				}
			});
			$('.numAnime6').each(function () {
				var position = $(this).offset().top;
				var scroll = $(window).scrollTop();
				var windowHeight = $(window).height();
		
				let roundDateLogEl05 = document.querySelector('.numAnime6');
		
				if (scroll > position - windowHeight + scrHightNum) {
					runAnimationLoop();
					function runAnimationLoop() {
						if (!graphAnimationFlag05) {
							graphAnimationFlag05 = true;
							let animationComplete = false;
							wait(1000)
							.then(() => {
								anime({
									targets: roundDateLogEl05,
									innerHTML: [0, 10000],
									easing: 'easeInOutExpo',
									duration: 1400,
									round: 1,
									update: ()=> {
										let num = Number(roundDateLogEl05.innerHTML).toLocaleString();
										roundDateLogEl05.innerHTML = num;
										
										const elem = $('.numAnime6');
										const elemTxt = elem.html();
										const elemTxtNum = elemTxt.length;
										const targetTxt = elemTxt.slice(0, 2);
										const txtLength = targetTxt.length;
										const searchNum = elemTxt.indexOf(targetTxt);
										const firstStr = elemTxt.slice(searchNum+txtLength, elemTxtNum);
										const targetStr = elemTxt.slice(searchNum, searchNum+txtLength);
										// 結合
										elem.html('<span class="comma">' + targetStr + '</span>' + firstStr);
									},
								});
								if (!graphAnimationFlag06) {
									graphAnimationFlag06 = true;
										const elements = document.querySelectorAll('.element');
										function animateElement(element) {
											anime({
												targets: element,
												opacity: [0, 1],
												duration: 1000,
												easing: 'easeInOutExpo',
											});
										}
										function animateElementsInRandomOrder() {
										const shuffledElements = [...elements].sort(() => Math.random() - 0.5);
									
										shuffledElements.forEach((element, index) => {
											setTimeout(() => {
												animateElement(element);
											}, index * 700);
										});
									}
									animateElementsInRandomOrder();
								}
								return wait(2000)
							}).then(() => {
								anime({
									targets: roundDateLogEl05,
									innerHTML: [10000, 30000],
									easing: 'easeInOutExpo',
									duration: 1400,
									round: 1,
									update: ()=> {
										let num = Number(roundDateLogEl05.innerHTML).toLocaleString();
										roundDateLogEl05.innerHTML = num;
										
										const elem = $('.numAnime6');
										const elemTxt = elem.html();
										const elemTxtNum = elemTxt.length;
										const targetTxt = elemTxt.slice(0, 2);
										const txtLength = targetTxt.length;
										const searchNum = elemTxt.indexOf(targetTxt);
										const firstStr = elemTxt.slice(searchNum+txtLength, elemTxtNum);
										const targetStr = elemTxt.slice(searchNum, searchNum+txtLength);
										// 結合
										elem.html('<span class="comma">' + targetStr + '</span>' + firstStr);
									},
								});
								return wait(2000)
							}).then(() => {
								anime({
									targets: roundDateLogEl05,
									innerHTML: [30000, 50000],
									easing: 'easeInOutExpo',
									duration: 1400,
									round: 1,
									update: ()=> {
										let num = Number(roundDateLogEl05.innerHTML).toLocaleString();
										roundDateLogEl05.innerHTML = num;
										
										const elem = $('.numAnime6');
										const elemTxt = elem.html();
										const elemTxtNum = elemTxt.length;
										const targetTxt = elemTxt.slice(0, 2);
										const txtLength = targetTxt.length;
										const searchNum = elemTxt.indexOf(targetTxt);
										const firstStr = elemTxt.slice(searchNum+txtLength, elemTxtNum);
										const targetStr = elemTxt.slice(searchNum, searchNum+txtLength);
										// 結合
										elem.html('<span class="comma">' + targetStr + '</span>' + firstStr);
									},
								});
								return wait(2000)
							}).then(() => {
								graphAnimationFlag06 = false;
								graphAnimationFlag05 = false;
								animationComplete = true;
								// アニメーションが完了していなければ再帰呼び出し
								if (animationComplete) {
									setTimeout(function() {
										$(".element").css({"opacity": "0"});
									}, 1000);
									runAnimationLoop();
								}
							})
						}
					}
				}
			});
		}

		numbersAreaFunc();

		$(window).on('load scroll', function () {
		  numbersAreaFunc();
		});
	}
}
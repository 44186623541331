//トップページ　キービジュアルスライダー
export function caseFunc() {
	let caseTop = $("#caseTop").length;
	if(caseTop){
		let subNavHeight = $("#subNav").innerHeight();
		let headerHeight = $("#header").innerHeight();
		subNavScrollFunc();
		subNavAccordionFuc();
		subNavHeightFunc();
		$(window).on('scroll', function () {
			subNavHeightFunc();
		});

		let resizecheck = $('#responsibleCheck').width();
		
		window.addEventListener('resize', () => {
		  resizecheck = $('#responsibleCheck').width();
		  subNavHeight = $("#subNav").innerHeight();
		  headerHeight = $("#header").innerHeight();
		  //アコーディオン初期化
		  subNavHeightFunc();
		}, false);

		function subNavHeightFunc(){
			let caseHeight = $(".caseDescListWrap").offset().top;
			let scrTopFlg = 0;
			let menuOpenFlg = 0;
			let subNavTarget = $("#subNav");
			let scrTop = $(window).scrollTop();
			if (menuOpenFlg == 0) {
				if (scrTop > caseHeight - subNavHeight - headerHeight) {
					scrTopFlg = 1;
					subNavTarget.addClass('changeHeigh');
		
				} else {
					scrTopFlg = 0;
					subNavTarget.removeClass('changeHeigh');
				}
			}
		}

	$(function(){
		var subNavCurrent = $("#subNavList .pageLinkList")
		var scroll = $(window).scrollTop();
		if(scroll == 0){
			$("#navCurrent").text("TRAIN").css({"color":"#1972EA"})
			if (subNavCurrent.hasClass('active')) {
				subNavCurrent.removeClass("active")
			  }
		}
	});

	function subNavScrollFunc(){
		// 今回の交差を監視する要素
		const boxes = document.querySelectorAll(".caseDescList");

		const options = {
			root: null,
			rootMargin: "-50% 0% -50% 0%",
			threshold: 0
		};
		const observer = new IntersectionObserver(doWhenIntersect, options);
		boxes.forEach(box => {
			observer.observe(box);
		});

		/**
		 * 交差したときに呼び出す関数
		 * @param entries
		 */
		function doWhenIntersect(entries) {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					activateIndex(entry.target);
				}
			});
		}
		/**
		 * 目次を変える関数
		 * @param element
		 */
		function activateIndex(element) {
			const currentActiveIndex = document.querySelector("#subNavList .active");
			if (currentActiveIndex !== null) {
				currentActiveIndex.classList.remove("active");
			}
			const newActiveIndex = document.querySelector(`a[href='#${element.id}']`).parentElement;
			newActiveIndex.classList.add("active");

			const currentTxt = document.querySelector(`a[href='#${element.id}']`).textContent
			// アンカー要素の色（colorプロパティ）を取得
			const anchorElement = document.querySelector(`a[href='#${element.id}']`)
			var computedStyle = window.getComputedStyle(anchorElement);
			var color = computedStyle.getPropertyValue("color");
			$("#navCurrent").text(currentTxt).css({"color":`${color}`})
		}
	}

	function subNavAccordionFuc(){
		const subNavAccordionBtn = $('#subNav .accordionBtn');
		const subNav = $('#subNavList a');
		let resizecheck = $('#responsibleCheck').width();
		let resizecheck2 = 0;
	  
		$(window).on('load resize', function () {
		  resizecheck = $('#responsibleCheck').width();
		  subNavAccordionMenuStart();
		  subNavCloseFunc();
		});

		//スマホのみサブナビをクリックでクローズさせる
		function subNavCloseFunc() {
			if (resizecheck == '2') {
			  subNav.on('click', subNavClickHandler);
			} else if (resizecheck == '1') {
			  subNav.off('click', subNavClickHandler);
			}
		  }
		  function subNavClickHandler() {
			subNavAccordionBtn.next('dd').slideUp(500);
			subNavAccordionBtn.removeClass('isOpen');
		  }
		  subNavCloseFunc()

		subNavAccordionBtn.on('click', function () {
		  if (resizecheck == '2') {
			if ($(this).hasClass('isOpen')) {
				$(this).next('dd').slideUp(500);
				$(this).removeClass('isOpen');
			} else {
				subNavAccordionBtn.next('dd').slideUp(500);
				subNavAccordionBtn.removeClass('isOpen');
				$(this).next('dd').slideDown(500);
				$(this).addClass('isOpen');
			}
		  }
		}); 
		
		var subNavAccordionMenuStart = function () {
			let resizecheck = $('#responsibleCheck').width();
			if (resizecheck == '2' && resizecheck != resizecheck2) {
				subNavAccordionBtn.next('dd').css('display', 'none');
				resizecheck2 = 2;
			} else if (resizecheck == '1' && resizecheck != resizecheck2) {
				subNavAccordionBtn.removeClass('isOpen');
				subNavAccordionBtn.next('dd').css('display', 'block');
				resizecheck2 = 1;
			}
		}
		subNavAccordionMenuStart();
	}


	}
}

export function worksFunc(){
	let worksArchiveTop = $("#worksArchiveTop").length

	if(worksArchiveTop){

		let listTarget = $(".workList .inner")
		let numberListLen = listTarget.length;
		// デフォルトの表示数
		let defaultNum = 9;
		let btnFlag = true;

		//9個以上の記事がある場合
		if(numberListLen > defaultNum){
			listTarget.slice(defaultNum).hide();

			// moreボタンがクリックされた時の処理
			if(btnFlag){
				$("#openBtn").click(function () {
					listTarget.slice(defaultNum).show();
					btnFlag = false;
					$(this).hide();
				});
			}
		} else {
			btnFlag = false;
			$("#openBtn").hide();
		}
	}
}
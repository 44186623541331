import Stickyfill from 'stickyfilljs/dist/stickyfill.min.js';

export function observeFunc() {
  const clientHeight = document.documentElement.clientHeight;
  const header = document.querySelector('#header');
  let option = {
    root: document.querySelector("#observerArea"),
    // 上下100px、左右20px手前で発火
    rootMargin: "100px 20px",
    // 交差領域が50%変化するたびに発火
    threshold: [0, 0.5, 1.0]
  };
  let observer = new IntersectionObserver((changes) => {
    for (let change of changes) {
      let rect = change.target.getBoundingClientRect();
      let h = (0 < rect.top && rect.top < clientHeight) // 対象の上端は表示領域に入っている
        ||
        (0 < rect.bottom && rect.bottom < clientHeight) // 対象の下端は表示領域に入っている
        ||
        (0 > rect.top && rect.bottom > clientHeight); // 上端下端も表示されてないがその間が表示されている
      if (h) {
        header.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
      }
    }
  }, option);
  let target = document.querySelector('.xyz');
  observer.observe(target);
}

export function fadeInFunc() {
  const options = {
    root: null,
    threshold: 0,
  };

  const callback = (function (changes) {
    for (let change of changes) {
      let target = change.target;
      if (change.isIntersecting) {
        target.classList.add('active');
      }
    }
  });

  const observer = new IntersectionObserver(callback, options);

  const targets = document.querySelectorAll('.fadeIn')
  for (let target of targets) {
    observer.observe(target)
  }


  commonAnimeFunc();
  function commonAnimeFunc() {
    const options = {
      root: null,
      rootMargin:"0px 0px -50% 0px",
      threshold: 0,
    };
  
    const callback = (function (changes) {
      for (let change of changes) {
        let target = change.target;
        if (change.isIntersecting) {
          target.classList.add('anime');
        }else {
          target.classList.remove('anime'); // 交差しない場合はクラスを削除
        }
      }
    });
  
    const observer = new IntersectionObserver(callback, options);
  
    const targets = document.querySelectorAll('.bubbleAnime')
    for (let target of targets) {
      observer.observe(target)
    }
  }

  imgTtlAnimeFunc();
  function imgTtlAnimeFunc() {
    const options = {
      root: null,
      rootMargin:"-50% 0px -50% 0px",
      threshold: 0,
    };
  
    const callback = (function (changes) {
      for (let change of changes) {
        let target = change.target;
        if (change.isIntersecting) {
          target.classList.add('animeStart');
        } else {
          target.classList.remove('animeStart');
        }
      }
    });
  
    const observer = new IntersectionObserver(callback, options);
  
    const targets = document.querySelectorAll('.imgTtlAnime')
    for (let target of targets) {
      observer.observe(target)
    }
  }
}

/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function sticyFunc() {
  // position: stickyがブラウザで使えるかチェックするための関数
  function detectSticky() {
    const div = document.createElement('div');
    div.style.position = 'sticky';
    return div.style.position.indexOf('sticky') !== -1;
  }
  // もしブラウザでposition: stickyが使えなければ、callStickyState関数を呼び出す
  if (!detectSticky()) {
    let jsSideNav = $('#pageTop');
    Stickyfill.add(jsSideNav);
  }
}

export function waitFunc() {
  const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
  wait(1000)
    .then(() => {
      console.log(1)
      return wait(1000)
    }).then(() => {
      console.log(2)
      return wait(1000)
    }).then(() => {
      console.log(3)
    })
}

export function loadingFunc() {
  $('#loaderBg').fadeOut(600, function() {
    $('#loader').delay(200).fadeOut();
  });
}

export function percentLoadingFunc() {
  // ローディング画面
  var images = document.getElementsByTagName('img'); // ページ内の画像取得
  var percent = document.getElementById('percent-text'); // パーセントのテキスト部分
  var gauge = document.getElementById('gauge'); // ゲージ
  var loading = document.getElementById('loading'); // ローディング要素
  var count = 0;
  var gaugeMax = 400; // ゲージの幅指定
  var current;
  var imgNum = images.length;

  // 画像の読み込み
  for (var i = 0; i < images.length; i++) {
    var img = new Image(); // 画像の作成
    // 画像読み込み完了したとき
    img.onload = function () {
      count += 1;
    }
    // 画像読み込み失敗したとき
    img.onerror = function () {
      count += 1;
    }
    img.src = images[i].src; // 画像にsrcを指定して読み込み開始
  };

  // ローディング処理
  var nowLoading = setInterval(function () {
    // 現在の読み込み具合のパーセントを取得
    current = Math.floor(count / imgNum * 100);
    // パーセント表示の書き換え
    percent.innerHTML = current;
    percent.style.transform = 'translateX(' + current + '%)';

    // ゲージの変更
    gauge.style.width = Math.floor(gaugeMax / 100 * current) + 'px';
    // 全て読み込んだ時

    if (count >= imgNum || count == imgNum) {
      // ローディング要素の非表示
      $('#loading').delay(200).fadeOut(2000);
      //loading.style.display = 'none';
      // ローディングの終了
      clearInterval(nowLoading);
    }
  }, 100);
}
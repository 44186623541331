export function headerFunc() {
  let headerPage = $('#header').length;
  const header = document.getElementById("header");
  const navBtn = $('#navBtn');
  const navMenu = $('#navMenu');
  let menuOpenFlg = 0;
  let scrollPosition;
  if (headerPage) {
    navBtn.on('click', function () {
      navBtn.toggleClass('isOpen');
      if (navBtn.hasClass('isOpen')) {
        scrollPosition = $(window).scrollTop();
        menuOpenFlg = 1;
        navMenu.addClass('isShow');
        $('body').addClass('fixedBody').css({
          'top': -scrollPosition
        });
      } else {
        menuOpenFlg = 0;
        navMenu.removeClass('isShow');
        $('body').removeClass('fixedBody').css({
          'top': 0
        });
        window.scrollTo(0, scrollPosition);
      }
    });
  }
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}